import React, { useState, useEffect } from 'react'
import './Landing.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose, faHistory, faSearch, faFire, faChartLine, faPaperclip } from '@fortawesome/free-solid-svg-icons'

const Landing: React.FC = () => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 })
  const [gradientPosition, setGradientPosition] = useState({ x: 0, y: 0 })
  const [hexagons, setHexagons] = useState<Array<{ x: number; y: number; originalX: number; originalY: number }>>([])
  const [showChangelog, setShowChangelog] = useState(false)

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      setMousePosition({ x: event.clientX, y: event.clientY })
    }

    window.addEventListener('mousemove', handleMouseMove)

    return () => {
      window.removeEventListener('mousemove', handleMouseMove)
    }
  }, [])

  useEffect(() => {
    const smoothenPosition = () => {
      const damping = 0.02
      const dx = mousePosition.x - gradientPosition.x
      const dy = mousePosition.y - gradientPosition.y
      setGradientPosition({
        x: gradientPosition.x + dx * damping,
        y: gradientPosition.y + dy * damping
      })
    }

    const animationFrame = requestAnimationFrame(smoothenPosition)

    return () => {
      cancelAnimationFrame(animationFrame)
    }
  }, [mousePosition, gradientPosition])

  useEffect(() => {
    const createHexagons = () => {
      const newHexagons = []
      for (let i = 0; i < 20; i++) {
        const x = Math.random() * window.innerWidth
        const y = Math.random() * window.innerHeight
        newHexagons.push({
          x: x,
          y: y,
          originalX: x,
          originalY: y
        })
      }
      setHexagons(newHexagons)
    }

    createHexagons()
  }, [])

  useEffect(() => {
    const animateHexagons = () => {
      setHexagons(prevHexagons =>
        prevHexagons.map(hexagon => {
          const dx = mousePosition.x - hexagon.x
          const dy = mousePosition.y - hexagon.y
          const distance = Math.sqrt(dx * dx + dy * dy)

          if (distance < 200) {
            const angle = Math.atan2(dy, dx)
            const targetX = hexagon.x - Math.cos(angle) * 200
            const targetY = hexagon.y - Math.sin(angle) * 200
            return {
              ...hexagon,
              x: hexagon.x + (targetX - hexagon.x) * 0.05,
              y: hexagon.y + (targetY - hexagon.y) * 0.05
            }
          } else {
            return {
              ...hexagon,
              x: hexagon.x + (hexagon.originalX - hexagon.x) * 0.05,
              y: hexagon.y + (hexagon.originalY - hexagon.y) * 0.05
            }
          }
        })
      )
    }

    const animationFrame = requestAnimationFrame(animateHexagons)

    return () => {
      cancelAnimationFrame(animationFrame)
    }
  }, [mousePosition])

  const gradientStyle = {
    background: `radial-gradient(circle at ${gradientPosition.x}px ${gradientPosition.y}px, rgba(59, 130, 246, 0.8) 0%, rgba(147, 51, 234, 0.8) 100%)`,
  }

  return (
    <div className="min-h-screen flex items-center justify-center relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-br from-purple-600 via-blue-500 to-green-400 opacity-70"></div>
      <div className="absolute inset-0" style={gradientStyle}></div>
      {hexagons.map((hexagon, index) => (
        <div
          key={index}
          className="absolute w-8 h-8 bg-white opacity-20 rounded-full animate-pulse"
          style={{
            left: `${hexagon.x}px`,
            top: `${hexagon.y}px`,
            transform: 'rotate(30deg)',
            clipPath: 'polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%)'
          }}
        ></div>
      ))}
      <div className="w-full h-full flex flex-col items-center justify-center text-center px-4 relative z-10">
        <img src="/hexacat.svg" alt="Hexacat Logo" className="mx-auto mb-8 w-40 h-40 sm:w-48 sm:h-48 md:w-64 md:h-64 filter invert drop-shadow-lg animate-bounce" />
        <h1 className="text-3xl sm:text-5xl md:text-6xl font-extrabold text-white mb-3 sm:mb-6 leading-tight hexacat drop-shadow-lg animate-wiggle">Hexacat</h1>
        <p className="text-base sm:text-xl md:text-2xl text-white mb-6 sm:mb-12 max-w-3xl mx-auto drop-shadow-md description animate-fade-in">Voimanlähteenä <u>React.js</u>, <u>Express.js</u> sekä <u>MongoDB</u></p>
      </div>
      <div className="absolute bottom-1 left-1/2 transform -translate-x-1/2 text-white text-xs opacity-50 text-center w-full px-4 animate-slide-up">
        <div className="max-w-xs mx-auto">
          License: Nuohous- Ja Ilmastointipalvelut Knuutila
        </div>
      </div>
      <button
        className="absolute bottom-4 right-4 bg-white bg-opacity-20 backdrop-filter backdrop-blur-md text-white px-5 py-2.5 rounded-full shadow-lg hover:shadow-xl transition-all duration-300 flex items-center space-x-2 border border-white border-opacity-30 hover:bg-opacity-30"
        onClick={() => setShowChangelog(true)}
      >
        <FontAwesomeIcon icon={faHistory} className="animate-pulse" />
        <span>Muutokset</span>
      </button>
      {showChangelog && (
        <div className="fixed inset-0 bg-black bg-opacity-30 backdrop-filter backdrop-blur-sm flex items-center justify-center z-50 transition-all duration-300 ease-in-out animate-fade-in">
          <div
            className="bg-white bg-opacity-20 backdrop-filter backdrop-blur-xl rounded-2xl shadow-2xl p-8 max-w-3xl mx-auto transform transition-all duration-500 ease-out scale-100 animate-zoom-in relative overflow-hidden border border-white border-opacity-20"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="absolute -top-20 -right-20 w-40 h-40 bg-purple-500 rounded-full opacity-20"></div>
            <div className="absolute -bottom-20 -left-20 w-40 h-40 bg-blue-500 rounded-full opacity-20"></div>

            <button
              className="absolute top-5 right-5 text-white hover:text-gray-200 bg-gray-800 bg-opacity-30 backdrop-filter backdrop-blur-md w-8 h-8 rounded-full flex items-center justify-center transition-all duration-300 hover:bg-opacity-50"
              onClick={() => setShowChangelog(false)}
            >
              <FontAwesomeIcon icon={faClose} />
            </button>

            <h2 className="text-2xl font-bold mb-8 text-white flex items-center">
              <span className="bg-purple-600 bg-opacity-70 rounded-full p-2 mr-3 shadow-lg">
                <FontAwesomeIcon icon={faHistory} />
              </span>
              Sovelluksen Muutoshistoria
            </h2>

            <div className="space-y-6 relative">
              <div className="absolute left-5 top-0 bottom-0 w-0.5 bg-gradient-to-b from-purple-500 to-blue-500 opacity-50"></div>

              <div className="pl-14 relative">
                <div className="absolute left-3 w-5 h-5 rounded-full bg-purple-500 border-4 border-white border-opacity-40 shadow-lg"></div>
                <div className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-xl p-4 shadow-lg border border-white border-opacity-10 transform transition-all hover:translate-x-1 hover:bg-opacity-20">
                  <div className="text-sm font-bold text-white flex items-center justify-between">
                    <div>18.03.2025</div>
                    <div className="bg-blue-500 bg-opacity-70 rounded-full px-3 py-1 text-xs">UI</div>
                  </div>
                  <div className="mt-2 text-white text-opacity-80">
                    <div className="flex items-start">
                      <FontAwesomeIcon icon={faPaperclip} className="mt-1 mr-2 text-blue-300" />
                      <p>Muutettu "Muutosloki" erilaiseksi.</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="pl-14 relative">
                <div className="absolute left-3 w-5 h-5 rounded-full bg-purple-500 border-4 border-white border-opacity-40 shadow-lg"></div>
                <div className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-xl p-4 shadow-lg border border-white border-opacity-10 transform transition-all hover:translate-x-1 hover:bg-opacity-20">
                  <div className="text-sm font-bold text-white flex items-center justify-between">
                    <div>18.03.2025</div>
                    <div className="bg-blue-500 bg-opacity-70 rounded-full px-3 py-1 text-xs">UI</div>
                  </div>
                  <div className="mt-2 text-white text-opacity-80">
                    <div className="flex items-start">
                      <FontAwesomeIcon icon={faSearch} className="mt-1 mr-2 text-blue-300" />
                      <p>Muutettu "Etsi asiakkaita" hakukentän sijaintia siten, että muut elementit eivät riko sivun rakennetta painamalla kenttää kasaan liian pieneksi sekä näkymättömäksi.</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="pl-14 relative">
                <div className="absolute left-3 w-5 h-5 rounded-full bg-blue-500 border-4 border-white border-opacity-40 shadow-lg"></div>
                <div className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-xl p-4 shadow-lg border border-white border-opacity-10 transform transition-all hover:translate-x-1 hover:bg-opacity-20">
                  <div className="text-sm font-bold text-white flex items-center justify-between">
                    <div>20.10.2024</div>
                    <div className="bg-green-500 bg-opacity-70 rounded-full px-3 py-1 text-xs">Ominaisuudet</div>
                  </div>
                  <div className="mt-2 text-white text-opacity-80">
                    <div className="flex items-start">
                      <FontAwesomeIcon icon={faFire} className="mt-1 mr-2 text-orange-300" />
                      <p>Lisätty tulisijat -määrä ominaisuus.</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="pl-14 relative">
                <div className="absolute left-3 w-5 h-5 rounded-full bg-green-500 border-4 border-white border-opacity-40 shadow-lg"></div>
                <div className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-xl p-4 shadow-lg border border-white border-opacity-10 transform transition-all hover:translate-x-1 hover:bg-opacity-20">
                  <div className="text-sm font-bold text-white flex items-center justify-between">
                    <div>04.10.2024</div>
                    <div className="bg-purple-500 bg-opacity-70 rounded-full px-3 py-1 text-xs">Analytiikka</div>
                  </div>
                  <div className="mt-2 text-white text-opacity-80">
                    <div className="flex items-start">
                      <FontAwesomeIcon icon={faChartLine} className="mt-1 mr-2 text-green-300" />
                      <p>Lisätty tilasto-graafi nuohouksille.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-8 flex justify-center">
              <button
                className="px-5 py-2 bg-white bg-opacity-10 hover:bg-opacity-20 backdrop-filter backdrop-blur-sm rounded-full text-white border border-white border-opacity-20 transition-all duration-300"
                onClick={() => setShowChangelog(false)}
              >
                Sulje
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Landing