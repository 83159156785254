import React, { useEffect, useState, useContext } from 'react'
import ky from 'ky'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDatabase, faDirections, faGear, faSpinner, faSync, faUser } from '@fortawesome/free-solid-svg-icons'
import IFireplace from '../../server/src/Interfaces/IFireplace'
import IAddress from '../../server/src/Interfaces/IAddress'
import IAddressCategory from '../../server/src/Interfaces/IAddressCategory'
import IAddressType from '../../server/src/Interfaces/IAddressType'
import EditCustomer from './EditCustomer'
import AssignToWorker from './AssignToWorker'
import { GlobalContext } from '../App'
import { updateAddressCategories, updateAddressTypes, updateFireplaces } from '../Modules/Data'
import { API_URL } from '../Globals'
import AddressPermissions from './AddressPermissions'
import AllCustomerChartModal from './AllCustomerChartModal'

const Customers: React.FC = () => {
  const { globalVariable, setGlobalVariable } = useContext(GlobalContext)
  const [searchText, setSearchText] = useState('')
  const [addresses, setAddresses] = useState<IAddress[]>([])
  const [selectedAddress, setSelectedAddress] = useState<IAddress | undefined>(undefined)
  const [fireplaceIds, setFireplaceIds] = useState<IFireplace[]>([])
  const [addressCategories, setAddressCategories] = useState<IAddressCategory[]>([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [addressTypes, setAddressTypes] = useState<IAddressType[]>([])
  const [assignAddress, setAssignAddress] = useState<IAddress | undefined>(undefined)
  const [permissionsUpdateTrigger, setPermissionsUpdateTrigger] = useState(0)
  const [addressCategoryId, setAddressCategoryId] = useState<number>(0)
  const [loading, setLoading] = useState(false)
  const [addressTotalCount, setAddressTotalCount] = useState(0)
  const [sweepModalCurrentYear, setSweepModalCurrentYear] = useState(false)
  const [sweepModalNextYear, setSweepModalNextYear] = useState(false)
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear())
  const [nextYear, setNextYear] = useState(new Date().getFullYear() + 1)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        await Promise.all([
          updateFireplaces(globalVariable.sessionToken, setFireplaceIds),
          updateAddressCategories(globalVariable.sessionToken, setAddressCategories),
          updateAddressTypes(globalVariable.sessionToken, setAddressTypes),
          updateAddresses()
        ])
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
    fetchAddressTotalCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalVariable.sessionToken])

  // Address Total Count: /api/address/statistic/total
  const fetchAddressTotalCount = async () => {
    try {
      const response = await ky.get(`${API_URL}/api/address/statistic/total`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${globalVariable.sessionToken}`,
          'Token': localStorage.getItem('token') as string
        }
      }).json<{ totalCount: number }>()
      setAddressTotalCount(response.totalCount)
    } catch (error) {
      console.error('Error fetching address total count:', error)
    }
  }

  const updateAddresses = async (addressCategoryId: number = 0) => {
    setAddresses([])
    setLoading(true)

    const url = globalVariable.permissions?.includes('ADMINISTRATOR')
      ? `${API_URL}/api/address/all/${addressCategoryId}`
      : `${API_URL}/api/address/assigned/all`

    try {
      const response = await ky.get(url, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${globalVariable.sessionToken}`,
          'Token': localStorage.getItem('token') as string
        }
      }).json<IAddress[]>()

      const sortedAddresses = response.sort((a, b) => {
        if (!a.nextSweepDate) return 1
        if (!b.nextSweepDate) return -1
        return new Date(a.nextSweepDate).getTime() - new Date(b.nextSweepDate).getTime()
      })
      setAddresses(sortedAddresses)
    } catch (error) {
      console.error('Error updating addresses:', error)
    } finally {
      setLoading(false)
    }
  }

  const searchAddresses = async (text: string, addressCategoryId?: number) => {
    setSearchText(text)
    if (!globalVariable.permissions?.includes('ADMINISTRATOR')) return

    if (text.length === 0) {
      await updateAddresses(addressCategoryId)
      return
    }

    setLoading(true)
    try {
      const response = await ky.post(`${API_URL}/api/address/search`, {
        json: { text, addressCategoryId },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${globalVariable.sessionToken}`,
          'Token': localStorage.getItem('token') as string
        }
      }).json<IAddress[]>()

      const sortedAddresses = response.sort((a, b) => {
        if (!a.nextSweepDate) return 1
        if (!b.nextSweepDate) return -1
        return new Date(a.nextSweepDate).getTime() - new Date(b.nextSweepDate).getTime()
      })
      setAddresses(sortedAddresses)
    } catch (error) {
      console.error('Error searching addresses:', error)
      setAddresses([])
    } finally {
      setLoading(false)
    }
  }

  const getFireplaceName = (id: number) =>
    fireplaceIds.find(item => item.id === id)?.name ?? null

  const openMapsLink = (address: IAddress) => {
    const url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(`${address.address}, ${address.zip} ${address.city}`)}`
    window.open(url, '_blank', 'noopener,noreferrer')
  }

  const handleCategoryChange = (value: string) => {
    const categoryId = Number(value);
    setAddressCategoryId(categoryId);
    searchAddresses(searchText, categoryId);
  };

  const isValidPhoneNumber = (number: string) => {
    // You can adjust this regex based on your specific phone number format
    if (number.trim() === '') return true;
    const phoneRegex = /^[\d\s+()-]{10,}$/;
    return phoneRegex.test(number);
  };

  const clearSelection = () => setSelectedAddress(undefined)

  const getAddressCategoryName = (id: number) =>
    addressCategories.find(item => item.id === id)?.name ?? null

  const filteredData = addresses.filter((item: IAddress) => {
    const fullName = `${item.firstname} ${item.lastname}`.toLowerCase()
    const searchLower = searchText.toLowerCase()
    return fullName.includes(searchLower) ||
      Object.values(item).some(value =>
        typeof value === 'string' && value.toLowerCase().includes(searchLower)
      )
  })

  return (
    <div className="container mx-auto mb-10 px-4 sm:px-6 lg:px-8">
      <AllCustomerChartModal isOpen={sweepModalCurrentYear} onClose={() => setSweepModalCurrentYear(false)} year={currentYear} />
      <AllCustomerChartModal isOpen={sweepModalNextYear} onClose={() => setSweepModalNextYear(false)} year={nextYear} />
      <div className="mb-6 flex flex-col sm:flex-row items-center">
        <button
          onClick={() => updateAddresses()}
          className="whitespace-nowrap mr-3 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-300 ease-in-out transform hover:scale-105 mb-2 sm:mb-0"
        >
          <FontAwesomeIcon icon={faSync} className="mr-2" />
          Päivitä
        </button>
        <input
          type="text"
          onChange={(e) => {
            const value = e.target.value
            searchAddresses(value, addressCategoryId)
          }}
          placeholder="Etsi asiakkaita..."
          className="w-full sm:flex-1 px-4 py-2 text-sm text-gray-700 bg-white border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-300 ease-in-out mb-2 sm:mb-0 sm:mx-2"
        />
        <select
          onChange={(e) => handleCategoryChange(e.target.value)}
          className="w-full sm:w-auto ml-0 sm:ml-3 px-4 py-2 text-sm text-gray-700 bg-white border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-300 ease-in-out"
        >
          <option value={0}>KAIKKI KATEGORIAT</option>
          {addressCategories.map((category) => (
            <option key={category.id} value={category.id === 0 ? "" : category.id}>
              {category.name}
            </option>
          ))}
        </select>
      </div>
      <div className="mb-6 flex flex-wrap items-center">
        <div className="bg-white shadow rounded-lg p-4 w-fit mr-2 mb-2 border border-gray-300">
          <div className="flex items-center justify-between">
            <h2 className="text-xs font-semibold text-gray-800">Kokonaismäärä </h2>
            <span className="text-xs font-bold text-blue-600">{addressTotalCount}</span>
          </div>
        </div>
        <div className="bg-white shadow rounded-lg p-4 w-fit mr-2 mb-2 border border-gray-300">
          <div className="flex items-center justify-between">
            <h2 className="text-xs font-semibold text-gray-800">Näytetään </h2>
            <span className="text-xs font-bold text-blue-600">{filteredData.length}</span>
          </div>
        </div>
        <button
          onClick={() => setSweepModalCurrentYear(true)}
          className="hidden sm:block bg-blue-500 shadow rounded-lg p-4 w-fit mr-2 mb-2 border border-gray-300 text-left focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-300 ease-in-out hover:bg-blue-600"
        >
          <div className="flex items-center justify-between">
            <h2 className="text-xs font-semibold text-white"><FontAwesomeIcon icon={faDatabase} className="mr-2" />Nuohottavat {currentYear}</h2>
          </div>
        </button>
        <button
          onClick={() => setSweepModalNextYear(true)}
          className="hidden sm:block bg-blue-500 shadow rounded-lg p-4 w-fit mr-2 mb-2 border border-gray-300 text-left focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-300 ease-in-out hover:bg-blue-600"
        >
          <div className="flex items-center justify-between">
            <h2 className="text-xs font-semibold text-white"><FontAwesomeIcon icon={faDatabase} className="mr-2" />Nuohottavat {nextYear}</h2>
          </div>
        </button>
      </div>
      {selectedAddress && <EditCustomer selectedAddress={selectedAddress} clearSelection={clearSelection} updateAddressListFn={updateAddresses} />}
      {assignAddress && <AssignToWorker sessionToken={globalVariable.sessionToken} address={assignAddress} cancelFn={() => setAssignAddress(undefined)} onSuccess={() => {
        setAssignAddress(undefined)
        setAddresses(prevAddresses =>
          prevAddresses.map(address =>
            address._id === assignAddress._id ? assignAddress : address
          )
        )
        setPermissionsUpdateTrigger(prev => prev + 1);
      }} />}

      {loading && (
        <div className="flex justify-center items-center">
          <FontAwesomeIcon icon={faSpinner} className="mr-2 text-blue-500 animate-spin" />
          <span className="text-blue-500">Ladataan...</span>
        </div>
      )}
      {!selectedAddress && filteredData.length === 0 && addresses.length > 0 && (
        <div className="flex justify-center items-center">
          <span className="text-gray-500">Ei tuloksia</span>
        </div>
      )}
      {!selectedAddress && filteredData.length > 0 && !loading && (
        <div className="grid gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          {filteredData.map((item: any, index: number) => {
            const cardColorClass = item.nextSweepDate
              ? new Date(item.nextSweepDate) < new Date()
                ? 'bg-red-100 border-red-500 border-2'
                : new Date(item.nextSweepDate) <= new Date(Date.now() + 30 * 24 * 60 * 60 * 1000)
                  ? 'bg-yellow-50 border-yellow-500 border-2'
                  : new Date(item.nextSweepDate) <= new Date(Date.now() + 60 * 24 * 60 * 60 * 1000)
                    ? 'bg-green-50 border-green-500 border-2'
                    : 'bg-white border-gray-300 border'
              : 'bg-white border-gray-300 border'

            const daysUntilNextSweep = item.nextSweepDate
              ? Math.ceil((new Date(item.nextSweepDate).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24))
              : null

            const ribbonClass = daysUntilNextSweep !== null
              ? daysUntilNextSweep <= 0
                ? 'bg-red-500'
                : daysUntilNextSweep <= 30
                  ? 'bg-yellow-500'
                  : daysUntilNextSweep <= 60
                    ? 'bg-green-500'
                    : ''
              : ''

            const ribbonText = daysUntilNextSweep !== null
              ? daysUntilNextSweep < 0
                ? 'Myöhässä'
                : daysUntilNextSweep <= 30
                  ? 'Alle 30 päivää'
                  : daysUntilNextSweep <= 60
                    ? 'Alle 60 päivää'
                    : ''
              : ''

            return (
              <div key={index} className={`${cardColorClass} rounded-lg shadow-md overflow-hidden transition-all duration-300 ease-in-out hover:shadow-lg relative`}>
                {ribbonClass && (
                  <div className={`absolute top-0 right-0 ${ribbonClass} text-white px-4 py-1 text-xs font-bold`}>
                    {ribbonText}
                  </div>
                )}
                <div className="p-6 mt-2">
                  <div className="flex justify-between items-center mb-4">
                    <h3 className="text-md font-semibold text-blue-600">
                      {item.firstname || item.lastname ? `${item.firstname} ${item.lastname}`.trim() : "Nimetön"}
                    </h3>
                    <div className="flex space-x-2">
                      <button className="text-gray-500 hover:text-blue-600 transition-colors duration-150 z-10" title="Muokkaa">
                        <FontAwesomeIcon icon={faGear} onClick={() => setSelectedAddress(item)} className="text-lg" />
                      </button>
                      <button className="text-gray-500 hover:text-blue-600 transition-colors duration-150 z-10" title="Ajo-ohjeet">
                        <FontAwesomeIcon icon={faDirections} onClick={() => openMapsLink(item)} className="text-lg" />
                      </button>
                      {globalVariable.permissions?.includes('ADMINISTRATOR') && (
                        <button className="text-gray-500 hover:text-blue-600 transition-colors duration-150 z-10" title="Osoita henkilölle">
                          <FontAwesomeIcon icon={faUser} onClick={() => setAssignAddress(item)} className="text-lg" />
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="mb-3 text-sm">
                    <p className="text-gray-600"><strong>Osoite:</strong> {item.address}{item.zip && item.city ? `, ${item.zip} ${item.city}` : ''}</p>
                  </div>
                  <div className="mb-3 text-sm">
                    <p className="text-gray-600"><strong>Kategoria:</strong> {getAddressCategoryName(item.addressCategoryId)}</p>
                  </div>
                  <div className="mb-3 text-sm">
                    <p className="text-gray-600"><strong>Puhelinnumerot:</strong> {item.phoneNumbers.map((number: string, idx: number) => (
                      <span key={idx} className="mr-2">
                        <a href={`tel:${number}`} className={`${isValidPhoneNumber(number) ? 'text-blue-600' : 'text-red-600 font-bold'} hover:underline`}>{number}</a>
                        {!isValidPhoneNumber(number) && <span className="bg-red-500 text-white text-xs font-bold ml-1 px-2 py-0.5 rounded">Virheellinen!</span>}
                      </span>
                    ))}</p>
                  </div>
                  <div className="mb-3 text-sm">
                    <p className="text-gray-600 mb-2"><strong>Tulisijat:</strong></p>
                    <table className="w-full border-collapse">
                      <tbody>
                        {item.selectedFireplaceIds.map((item: any) => (
                          <tr key={item.id} className="border-b border-gray-200 hover:bg-gray-50 transition-colors duration-150 ease-in-out">
                            <td className="p-2 w-6">
                              <svg className="w-4 h-4 text-orange-500 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 18.657A8 8 0 016.343 7.343S7 9 9 10c0-2 .5-5 2.986-7C14 5 16.09 5.777 17.656 7.343A7.975 7.975 0 0120 13a7.975 7.975 0 01-2.343 5.657z" />
                              </svg>
                            </td>
                            <td className="p-2 text-xs text-gray-700 text-left">
                              {getFireplaceName(item.id)}
                              <span className="ml-2 bg-gray-200 text-black px-2 py-1 rounded-full border border-gray-300">
                                {item.count} KPL
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  {item.additionalInfo.length > 0 && (
                    <div className="mb-3 text-sm">
                      <p className="text-gray-600 mb-2"><strong>Lisätiedot:</strong></p>
                      <p className="w-full p-2 text-xs text-gray-600 bg-gray-100 border border-gray-300 rounded">
                        {item.additionalInfo.length > 200
                          ? `${item.additionalInfo.slice(0, 200)}...`
                          : item.additionalInfo}
                      </p>
                    </div>
                  )}
                  {globalVariable.permissions?.includes('ADMINISTRATOR') && (
                    <div className="mb-3 text-sm">
                      <p className="text-gray-600 mb-2"><strong>Oikeudet:</strong></p>
                      <AddressPermissions addressId={item._id} sessionToken={globalVariable.sessionToken} updateTrigger={permissionsUpdateTrigger} />
                    </div>
                  )}
                  <div className="mt-4 flex justify-between items-start text-xs">
                    <div>
                      <p className="text-gray-600 font-semibold mb-1">Viimeksi nuohottu:</p>
                      {item.lastSweepDate ? (
                        <p>
                          {new Date(item.lastSweepDate).toLocaleDateString('fi-FI')}
                          {' '}
                          <span className="text-gray-500">({Math.ceil((new Date().getTime() - new Date(item.lastSweepDate).getTime()) / (1000 * 60 * 60 * 24))} pv sitten)</span>
                        </p>
                      ) : (
                        <p className="text-gray-400">Ei tietoa</p>
                      )}
                    </div>
                    <div>
                      <p className="text-gray-600 font-semibold mb-1">Seuraava nuohous:</p>
                      {item.nextSweepDate ? (
                        <p>
                          {new Date(item.nextSweepDate).toLocaleDateString('fi-FI')}
                          {' '}
                          <span className="text-gray-500">({Math.ceil((new Date(item.nextSweepDate).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24))} pv)</span>
                        </p>
                      ) : (
                        <p className="text-gray-400">Ei sovittua</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default Customers